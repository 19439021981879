import React from "react";
import "./partners.css";

const Partners = () => {
  return (
    <div className="main-container">
      <div
        className="partner-wrap"
        style={{
          maxWidth: "1100px",
        }}
      >
        <div className="brand-img">
          <div className="drand-img-con">
            <img src={require("../../img/airbnb.png")} />
          </div>
          <div className="drand-img-con">
            <img src={require("../../img/pf.png")} />
          </div>
          <div className="drand-img-con">
            <img src={require("../../img/dz.png")} />
          </div>
          <div className="drand-img-con">
            <img src={require("../../img/bt.png")} />
          </div>
          <div className="drand-img-con">
            <img src={require("../../img/ex.png")} />
          </div>
          <div className="drand-img-con">
            <img src={require("../../img/vr.webp")} />
          </div>
          <div className="drand-img-con">
            <img src={require("../../img/book.png")} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
