import React from "react";

import "./policy.css";
import HaddingBanner from "../../components/headingbanner/HaddingBanner";

const Refund = () => {
  return (
    <div className="pp-container">
      <HaddingBanner hedding="payment & refund" />
      <div
        className="polocy-info"
        style={{
          maxWidth: "1200px",
        }}
      >
        <div className="po-info-con">
          <h3> Payment </h3>
          <p>
            The Guest provides his / her payment information(i) to pre - pay the
            reservation before the stay, (ii) as a guarantee of the reservation
            <br />
            <br />
            staycia has chosen Payfort / Stripe - to secure online payments by
            bank card.The credit card of the Guest is examined for its validity
            by these partners and can be refused for several reasons: card
            stolen or blocked, limit reached, input error, etc.In the event of
            any problem, the Guest will have to approach his / her bank and
            staycia or any other entity to confirm his / her reservation of the
            Service and payment method.The online payment methods(cards, wallet,
            etc.) availabel and mentioned on the payment page of the Site may be
            Visa and Mastercard(this list is subject to change). <br />
            <br />
            In the event of payment with staycia Reservations / GEM’ s or any
            other entity and partners, staycia or any other entity may accept
            different means of payment, but the Guest must present the credit
            card upon arrival via the Guest Experience Makers(GEM) that they
            used to guarantee the reservation or make the pre - payment.staycia
            may also ask the Guest to present an identification document for the
            purpose of credit card fraud prevention.If the Guest has not pre -
            paid for his / her stay online, the GEM’ s may, upon arrival, ask
            the Guest for a deposit or an authorisation to debit the credit card
            to guarantee payment of the sums corresponding to the services
            consumed on - site. <br />
            <br />
            In the event of the Guest 's failure to show up on the first day of
            his / her reservation of an Accommodation Service("no show"), the
            Guess 's reservation will be cancelled in full. The Guest is
            informed that in such a case, staycia will put his or her assigned
            unit up for sale and if the reservation is non - cancellabel / non -
            exchangeable / non - refundable, the Guest will not be entitled to
            any reimbursement or compensation.If the reservation is guaranteed
            by credit card or had been the subject of a bank pre -
            authorisation, staycia will charge the Guest the cost of the first
            night on the credit card that has been given as a guarantee for the
            reservation and any additional nights of the reservation will be
            cancelled free of charge, unless otherwise indicated in the
            Conditions. <br />
            <br />
            At the time of pre - payment, the amount that is debited when
            reserving the Service includes the total amount indicated at the
            time of reservation(including all applicable taxes) An invoice will
            be sent in electronic format to the e - mail address communicated by
            the Guest at the time of his / her reservation; if the Guest wishes
            to receive an invoice in paper format, he / she must expressly
            request this from the GEM’ s or Reservations.
          </p>
          <h4> Security deposits refund </h4>
          <ul>
            <li>
              
              Debit / Credit card and payment link 45 days after check out.
            </li>
            <li> Cash Deposits– 48 hours after check out. </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Refund;
