import React from "react";
import { Link } from "react-router-dom";
import HaddingBanner from "../../components/headingbanner/HaddingBanner";
const Errorpage = () => {
  return (
    <div className="err-container">
      <HaddingBanner hedding=" Error" />
    </div>
  );
};

export default Errorpage;
