import React, { useState } from "react";
import "./properties.css";
import List from "../../components/List/List";
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import Card from "../../components/Card/Card";

const Properties = () => { const { data, loading, error } = useFetch(
    `/properties?populate=*`
  );

  return (
    <div class="main-container">
      <div class="featured-con-main" style={{ maxWidth: "1000px" }}>
        <div>
          <h3>Stay at our top unique properties</h3>
          <p>Hand picked from our Staycia team, just for you</p>
        </div>
        <div class="featured-con">
          {error
            ? "Data Loading Error"
            : loading
            ? ".."
            : data?.map((item) => <Card item={item} key={item.id} />)}
        </div>
      </div>
    </div>
  );
};


export default Properties;
