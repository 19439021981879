import React from 'react'
import './place.scss'
import useFetch from "../../hooks/useFetch";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";

const Place = ({ isOpen, closePlace, children }) => {


    const id = useParams().id;
     const { data, loading, error } = useFetch(`/properties/${id}?populate=*`);


  return isOpen ? (
    <div className="place">
      <div className="place-content">
        <button className="close-button" onClick={closePlace}>
          Close
        </button>
        <div className="list_wrap">
          <h3>What this place offers</h3>
          <br />
          <div className="list_in">
            <div>
              <ul>
                <li>
                  <div className="list_item_div">
                    <div className="list_icon">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon1?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="list_text">
                      <p>{data?.attributes?.icon1_title}</p>
                      <small>{data?.attributes?.icon1_subtitle}</small>
                    </div>
                  </div>
                  <div className="list_item_div">
                    <div className="list_icon">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon2?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="list_text">
                      <p>{data?.attributes?.icon2_title}</p>
                      <small>{data?.attributes?.icon2_subtitle}</small>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="list_item_div">
                    <div className="list_icon">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon3?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="list_text">
                      <p>{data?.attributes?.icon3_title}</p>
                      <small>{data?.attributes?.icon3_subtitle}</small>
                    </div>
                  </div>
                  <div className="list_item_div">
                    <div className="list_icon">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon4?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="list_text">
                      <p>{data?.attributes?.icon4_title}</p>
                      <small>{data?.attributes?.icon4_subtitle}</small>
                    </div>
                  </div>

                  <div className="list_item_div">
                    <div className="list_icon">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon5?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="list_text">
                      <p>{data?.attributes?.icon5_title}</p>
                      <small>{data?.attributes?.icon5_subtitle}</small>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="list_item_div">
                    <div className="list_icon">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon6?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="list_text">
                      <p>{data?.attributes?.icon6_title}</p>
                      <small>{data?.attributes?.icon6_subtitle}</small>
                    </div>
                  </div>

                  <div className="list_item_div">
                    <div className="list_icon">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon7?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="list_text">
                      <p>{data?.attributes?.icon7_title}</p>
                      <small>{data?.attributes?.icon7_subtitle}</small>
                    </div>
                  </div>

                  <div className="list_item_div">
                    <div className="list_icon">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon8?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="list_text">
                      <p>{data?.attributes?.icon8_title}</p>
                      <small>{data?.attributes?.icon8_subtitle}</small>
                    </div>
                  </div>

                  <div className="list_item_div">
                    <div className="list_icon">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon9?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="list_text">
                      <p>{data?.attributes?.icon9_title}</p>
                      <small>{data?.attributes?.icon9_subtitle}</small>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="list_item_div">
                    <div className="list_icon">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon10?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="list_text">
                      <p>{data?.attributes?.icon10_title}</p>
                      <small>{data?.attributes?.icon10_subtitle}</small>
                    </div>
                  </div>
                  <div className="list_item_div">
                    <div className="list_icon">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon11?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="list_text">
                      <p>{data?.attributes?.icon11_title}</p>
                      <small>{data?.attributes?.icon11_subtitle}</small>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="list_item_div">
                    <div className="list_icon">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon12?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="list_text">
                      <p>{data?.attributes?.icon12_title}</p>
                      <small>{data?.attributes?.icon12_subtitle}</small>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="list_item_div">
                    <div className="list_icon">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon13?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="list_text">
                      <p>{data?.attributes?.icon13_title}</p>
                      <small>{data?.attributes?.icon13_subtitle}</small>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="list_item_div">
                    <div className="list_icon">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon14?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="list_text">
                      <p>{data?.attributes?.icon14_title}</p>
                      <small>{data?.attributes?.icon14_subtitle}</small>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="list_item_div">
                    <div className="list_icon">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon15?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="list_text">
                      <p>{data?.attributes?.icon15_title}</p>
                      <small>{data?.attributes?.icon15_subtitle}</small>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="list_item_div">
                    <div className="list_icon">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon16?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="list_text">
                      <p>{data?.attributes?.icon16_title}</p>
                      <small>{data?.attributes?.icon16_subtitle}</small>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="list_item_div">
                    <div className="list_icon">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon17?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="list_text">
                      <p>{data?.attributes?.icon17_title}</p>
                      <small>{data?.attributes?.icon17_subtitle}</small>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="list_item_div">
                    <div className="list_icon">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon18?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="list_text">
                      <p>{data?.attributes?.icon18_title}</p>
                      <small>{data?.attributes?.icon18_subtitle}</small>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="list_item_div">
                    <div className="list_icon">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon19?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="list_text">
                      <p>{data?.attributes?.icon19_title}</p>
                      <small>{data?.attributes?.icon19_subtitle}</small>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="list_item_div">
                    <div className="list_icon">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon20?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="list_text">
                      <p>{data?.attributes?.icon20_title}</p>
                      <small>{data?.attributes?.icon20_subtitle}</small>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="list_item_div">
                    <div className="list_icon">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon21?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="list_text">
                      <p>{data?.attributes?.icon21_title}</p>
                      <small>{data?.attributes?.icon21_subtitle}</small>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="list_item_div">
                    <div className="list_icon">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon22?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="list_text">
                      <p>{data?.attributes?.icon22_title}</p>
                      <small>{data?.attributes?.icon22_subtitle}</small>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="list_item_div">
                    <div className="list_icon">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon23?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="list_text">
                      <p>{data?.attributes?.icon23_title}</p>
                      <small>{data?.attributes?.icon23_subtitle}</small>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="list_item_div">
                    <div className="list_icon">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon24?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="list_text">
                      <p>{data?.attributes?.icon24_title}</p>
                      <small>{data?.attributes?.icon24_subtitle}</small>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="list_item_div">
                    <div className="list_icon">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon25?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="list_text">
                      <p>{data?.attributes?.icon25_title}</p>
                      <small>{data?.attributes?.icon25_subtitle}</small>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="list_item_div">
                    <div className="list_icon">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon26?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="list_text">
                      <p>{data?.attributes?.icon26_title}</p>
                      <small>{data?.attributes?.icon26_subtitle}</small>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="list_item_div">
                    <div className="list_icon">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon27?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="list_text">
                      <p>{data?.attributes?.icon27_title}</p>
                      <small>{data?.attributes?.icon27_subtitle}</small>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="list_item_div">
                    <div className="list_icon">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon28?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="list_text">
                      <p>{data?.attributes?.icon28_title}</p>
                      <small>{data?.attributes?.icon28_subtitle}</small>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="list_item_div">
                    <div className="list_icon">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon29?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="list_text">
                      <p>{data?.attributes?.icon29_title}</p>
                      <small>{data?.attributes?.icon29_subtitle}</small>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="list_item_div">
                    <div className="list_icon">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon30?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="list_text">
                      <p>{data?.attributes?.icon30_title}</p>
                      <small>{data?.attributes?.icon30_subtitle}</small>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="list_item_div">
                    <div className="list_icon">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon12?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="list_text">
                      <p>Security cameras on property</p>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="list_item_div">
                    <div className="list_icon">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon12?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="list_text">
                      <p>Heating</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default Place
