import React from "react";
import "./about.css";
import ab from "../../img/about.jpg";
import about from "../../img/S.jpg";
import story from "../../img/family.jpg";
import HaddingBanner from "../../components/headingbanner/HaddingBanner";
import Stayupdate from "../../components/stayupdate/Stayupdate";

const About = () => {
  return (
    <div className="ser-container">
      <div>
        <div
          style={{
            backgroundImage: `url(${ab})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            objectFit: "contain",
          }}
          className="page-head"
        >
          <h1> Who we are </h1>
        </div>
      </div>

      <div
        className="section-about-container"
        style={{
          maxWidth: "1100px",
        }}
      >
        <div className="about-heading">
          <h1> Our Vision </h1>
        </div>
        <div className="about-heading-sec">
          <div className="sec-ab-con-text">
            <div className="about-span">
              <>
                <p>
                  Our vision is to create a sanctuary where guests can immerse
                  themselves in the beauty of Dubai.We believe that every moment
                  of your stay should be filled with joy and tranquility.That 's
                  why we focus on providing thoughtful touches, personalized
                  service, and modern amenities that elevate your experience to
                  new heights.
                </p>
              </>
            </div>
            <div className="about-list">
              <ul>
                <li>
                  <img src={require("../../img/check.png")} alt="Staycia_img" />
                  Curated properties: Exquisite properties selected for their
                  elegance and sophistication
                </li>
                <li>
                  <img src={require("../../img/check.png")} alt="Staycia_img" />
                  Dedicated professionals: A passionate team delivering
                  unforgettable experiences.
                </li>
                <li>
                  <img src={require("../../img/check.png")} alt="Staycia_img" />
                  Trusted partner: Comprehensive short - let management services
                  for homeowners.
                </li>
                <li>
                  <img src={require("../../img/check.png")} alt="Staycia_img" />
                  Alleviating burdens: Handling all aspects of property
                  management for peace of mind.
                </li>
              </ul>
            </div>
            <div className="about-span">
              <>
                <p>
                  Join us in redefining luxury travel in Dubai with
                  Staycia.Discover a new level of comfort, convenience, and
                  sophistication.
                </p>
              </>
            </div>
          </div>
          <div className="sec-ab-con-img">
            <div
              className="ab-sec-img"
              style={{
                backgroundImage: `url(${about})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
          </div>
        </div>
        <div className="section-about-container">
          <div className="about-heading-sec">
            <div className="sec-ab-con-img">
              <div
                className="ab-sec-img"
                style={{
                  backgroundImage: `url(${story})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
            </div>
            <div className="sec-ab-con-text">
              <div className="about-span-center">
                <div className="about-heading">
                  <h1> Our Story </h1>
                </div>
                <>
                  <p>
                    At Staycia, our story is one that is rooted in passion,
                    dedication and a deep understanding of the luxury travel
                    industry.Our journey began with a vision to redefine the
                    concept of short - term rentals and create an unparalleled
                    experience for both guests and homeowners alike. <br />
                    <br />
                    Our founders, driven by their love for travel and their
                    desire to provide exceptional accommodations, embarked on a
                    mission to bridge the gap between luxury and
                    hospitality.With a clear focus on delivering a seamless and
                    unforgettable stay, we set out to curate a collection of the
                    most exquisite holiday homes in Dubai 's most prestigious
                    neighborhoods. <br />
                    <br />
                    What sets us apart is our unwavering commitment to
                    excellence in every aspect of our service.From the moment
                    you step foot into one of our carefully selected properties,
                    you will be greeted by a blend of sophistication, comfort,
                    and unparalleled attention to detail.Each home is
                    thoughtfully designed and equipped with the finest amenities
                    to ensure that your stay is nothing short of extraordinary.
                    <br />
                    <br />
                    But Staycia is more than just beautiful properties.It is a
                    reflection of our dedication to creating meaningful
                    connections and unforgettable memories.Our team of
                    experienced professionals is here to cater to your every
                    need, providing personalized concierge services and insider
                    knowledge of Dubai 's best-kept secrets. Whether it' s
                    arranging exclusive experiences, recommending the finest
                    dining options, or organizing private transportation, we go
                    above and beyond to exceed your expectations. <br />
                    <br />
                    We believe that true luxury lies in the ability to provide a
                    home away from home.With Staycia, you will experience the
                    warmth and comfort of a private residence, combined with the
                    convenience and amenities of a world - class hotel.Our
                    commitment to quality and excellence is the foundation upon
                    which we have built lasting relationships with our guests
                    and homeowners.
                  </p>
                </>
                {/*
                                <div className="about-sign-sec">
                              <div className="about-sign-sec-one">
                                <strong>before you arrive</strong>
                                <p>
                                  <span>CEO </span>
                                </p>
                              </div>
                              <div className="about-sign-sec-one">
                                <strong>before you arrive</strong>
                                <p>
                                  <span>CEO </span>
                                </p>
                              </div>
                            </div>      
                            */}
              </div>
            </div>
          </div>
        </div>
        <div className="about-team">
          <div className="about-heading-team">
            <h1> Our Team </h1>
          </div>
          <div className="img-card-con">
            <div className="img-card">
              <img
                className="slide-img-team"
                src={require("../../img/moe.jpeg")}
                alt="Staycia_img"
              />
              <span> CEO & Founder </span> <h5> MOE ISMAIL </h5>
            </div>
            <div className="img-card">
              <img
                className="slide-img-team"
                src={require("../../img/S.png")}
                alt="Staycia_img"
              />
              <span> CFO & Co - Founder </span> <h5> SABINA SINHA </h5>
            </div>
            <div className="img-card">
              <img
                className="slide-img-team"
                src={require("../../img/Arbab.JPG")}
                alt="Staycia_img"
              />
              <span> Marketing Manager </span> <h5> ARBAB AHMAD </h5>
            </div>
          </div>
        </div>
      </div>

      <Stayupdate />
    </div>
  );
};

export default About;
