import React from "react";
import "./home.css";
import Header from "../../components/header/Header";
import Attraction from "../../components/attraction/attraction";
import Testimonial from "../../components/testimonial/Testimonial";
import ListPropaty from "../../components/listpropatry/ListPropaty";
import Stayupdate from "../../components/stayupdate/Stayupdate";
import Partners from "../../components/partners/Partners";
import HomeCarousel from "../../components/carousel/HomeCarousel";
import Featured from "../../components/featured/Featured";

const Home = () => {
  return (
    <div>
      <Header />
      <HomeCarousel />
      <Featured type="featured"/>
      <Attraction />

      <ListPropaty />
      <Stayupdate />
      <Partners />
    </div>
  );
};

export default Home;
