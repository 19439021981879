import React from "react";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";

const HomeCarousel = () => {
  
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  
  return (
    <div className="main-container">
      <div
        className="slide-show"
        style={{
          maxWidth: "1200px",
        }}
      >
        <div className="slide-show-text">
          <h1> Why Staycia ? </h1>
          <p>
            Staycia is the go - to holiday home company, offering a diverse
            range of meticulously curated properties and top notch service for
            guests.Homeowners benefit from hassle - free management services,
            maximizing rental income.Discover why Staycia is the preferred
            choice for unforgettable getaways and reliable property management.
          </p>
          <Link to="/why">
            <button onClick={scrollToTop} className="primary-btn">
              {" "}
              Learn more{" "}
            </button>
          </Link>
        </div>
        <div className="top-slide-img">
          <Carousel className="carousel" autoPlay interval="5000" infiniteLoop>
            <div className="cal-img">
              <img src={require("../../img/lo-01.jpeg")} />
            </div>
            <div className="cal-img">
              <img src={require("../../img/lo-02.jpeg")} />
            </div>
            <div className="cal-img">
              <img src={require("../../img/lo-03.jpeg")} />
            </div>
            <div className="cal-img">
              <img src={require("../../img/lo-04.jpeg")} />
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default HomeCarousel;
