import React from "react";
import pgimage from "../../img/about.jpg";
import "./haddingBanner.css";

const HaddingBanner = (props) => {
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${pgimage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          objectFit: "contain",
        }}
        className="page-head"
      >
        <h1> {props.hedding} </h1>
      </div>
    </div>
  );
};

export default HaddingBanner;
