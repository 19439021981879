import React from "react";
import "./landLord.css";

import Counter from "./Counter";
import HaddingBanner from "../../components/headingbanner/HaddingBanner";
import Partners from "../../components/partners/Partners";
import Stayupdate from "../../components/stayupdate/Stayupdate";

const LandLord = () => {
  return (
    <>
      <div className="land-lo-container">
        <HaddingBanner hedding=" Be Our Host" />

        <div
          style={{
            maxWidth: "1200px",
          }}
        >
          <div className="land-lord-card">
            <div className="la-lo-se-one">
              <h1> Sample text </h1> <h4> services may be of interest! </h4>
              <p>
                If you are looking for the ultimate in relaxation, the least
                hassle from your stay, or just want to be pampered like a movie
                star– some of these additional services may be of interest!
              </p>
            </div>
            <div className="la-lo-se-two">
              <div className="l-l-sec">
                <img
                  className="slide-img-la-lo"
                  src={require("../../img/i_1.png")}
                />
                <h5> HIGH RENTAL YIELD </h5>
                <p> Get more rent and stable cash flow </p>
              </div>
              <div className="l-l-sec">
                <img
                  className="slide-img-la-lo"
                  src={require("../../img/i_2.png")}
                />
                <h5> VERIFIED GUESTS </h5>
                <p>
                  Tourists and Business Travelers are screened during the
                  booking
                </p>
              </div>
              <div className="l-l-sec">
                <img
                  className="slide-img-la-lo"
                  src={require("../../img/i_3.png")}
                />
                <h5> WORRY - FREE MAINTENANCE </h5>
                <p>
                  We handle all the maintenance so that your property retains
                  value
                </p>
              </div>
              <div className="l-l-sec">
                <img
                  className="slide-img-la-lo"
                  src={require("../../img/i_4.png")}
                />
                <h5> FLEXIBLE LEASE </h5>
                <p> You can get back your property on shorter notice </p>
              </div>
            </div>
          </div>

          <div className="counter-wrap">
            <div className="photo-profile"> {/* <img  /> */} </div>
            <div className="numbers">
              <div className="num-con">
                <Counter number={4.5} title="Stars Rating" />
              </div>
              <div className="num-con">
                <Counter number={343} title="Properties Managed" />
              </div>
              <div className="num-con">
                <Counter number={95} title="Occupancy Rate" />
              </div>
              <div className="num-con">
                <Counter number={4230} title="Happy Guests" />
              </div>
            </div>
          </div>

          <div className="steps">
            <div className="steps-head">
              <h1> On - demand help to make the best offer </h1>
              <p>
                In publishing and graphic design, Lorem ipsum is a placeholder
                text commonly used to demonstrate the visual form of a document
                or a typeface without relying on meaningful content In
                publishing and graphic design, Lorem ipsum is a placeholder text
                commonly used to demonstrate the visual form of a document or a
                typeface without relying on meaningful content
              </p>
            </div>
            <div className="steps-btn">
              <button class="primary-btn"> Learn more </button>
              <button class="primary-btn"> Learn more </button>
            </div>
            <div className="step-sec">
              <div className="step-card">
                <div className="step-card-in">
                  <p> STEP </p>
                  <div className="steps-num">
                    <h1> 1 </h1>
                  </div>
                  <h5> Check the facts </h5>
                  <p>
                    a typeface without relying on meaningful content In
                    publishing and graphic design, Lorem ipsum is a placeholder
                    text commonly used to demonstrate the visual form of a
                    document or a typeface without relying on meaningful content
                  </p>
                </div>
              </div>
              <div className="step-card">
                <div className="step-card-in">
                  <p> STEP </p>
                  <div className="steps-num">
                    <h1> 2 </h1>
                  </div>
                  <h5> Talk to an expert </h5>
                  <p>
                    a typeface without relying on meaningful content In
                    publishing and graphic design, Lorem ipsum is a placeholder
                    text commonly used to demonstrate the visual form of a
                    document or a typeface without relying on meaningful content
                  </p>
                </div>
              </div>
              <div className="step-card">
                <div className="step-card-in">
                  <p> STEP </p>
                  <div className="steps-num">
                    <h1> 3 </h1>
                  </div>
                  <h5> Pick the bid that suits you </h5>
                  <p>
                    a typeface without relying on meaningful content In
                    publishing and graphic design, Lorem ipsum is a placeholder
                    text commonly used to demonstrate the visual form of a
                    document or a typeface without relying on meaningful content
                  </p>
                </div>
              </div>
            </div>
          </div>

          <Partners />
        </div>

        <Stayupdate />
      </div>
    </>
  );
};

export default LandLord;
