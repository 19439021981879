import React from "react";
import "./header.css";
import image from "../../img/backimg.jpeg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="main-container">
      <div className="hero-space">
        <div
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className="banner"
        >
          <div
            className="banner-in"
            style={{
              maxWidth: "1200px",
            }}
          >
            <div className="hero-text">
              <h1>
                Elevating Luxury <span> Retreats </span>
              </h1>
              <h1>
                to Unforgettable <span> Experiences </span>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
