import React, { useState, useEffect } from "react";
import Counter from "../landlord/Counter";
import Partners from "../../components/partners/Partners";
import why from "../../img/why.jpg";
import "../landlord/landLord.css";
import "./why.css";
import HaddingBanner from "../../components/headingbanner/HaddingBanner";

function Why() {
  const [isActive, setIsActive] = useState({
    id: "divOne",
  });
  useEffect(() => {
    console.log(isActive);
  }, [isActive]);
  const hideShowDiv = (e) => {
    setIsActive({
      id: e.target.id,
    });
  };
  return (
    <div className="ser-container-why">
      <div>
        <div
          style={{
            backgroundImage: `url(${why})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            objectFit: "contain",
          }}
          className="page-head"
        >
          <h1> Why Staycia </h1>
        </div>
      </div>
      <div className="why-heading">
        <h2> We offer hassle - free experience to our... </h2>
      </div>

      
      <div
        className="button-section"
        style={{
          maxWidth: "1100px",
        }}
      >
        <div className="btn-one">
          <button
            id="divOne"
            onClick={(e) => {
              hideShowDiv(e);
            }}
            className="primary-btn-why"
          >
            Guests
          </button>
        </div>
        <div className="btn-two">
          <button
            id="divTwo"
            onClick={(e) => {
              hideShowDiv(e);
            }}
            className="primary-btn-why"
          >
            Hosts
          </button>
        </div>
      </div>


      <div
        className="my-div  "
        style={{
          maxWidth: "1100px",
        }}
      >
        <div
          className={isActive.id === "divOne" ? `divOne` : "divOne d-none  "}
        >
          <div>
            <div className="land-lord-card">
              <div className="la-lo-se-two">
                <div className="l-l-sec">
                  <img
                    className="slide-img-la-lo"
                    src={require("../../img/i_1.png")}
                  />
                  <h5> Luxury Accommodations </h5>
                  <p>
                    Indulge in the epitome of luxury with our handpicked
                    selection of exquisite holiday homes
                  </p>
                </div>
                <div className="l-l-sec">
                  <img
                    className="slide-img-la-lo"
                    src={require("../../img/i_2.png")}
                  />
                  <h5> Seamless Booking Experience </h5>
                  <p>
                    Enjoy a hassle - free reservation process with our user -
                    friendly platform and secure payment options
                  </p>
                </div>
                <div className="l-l-sec">
                  <img
                    className="slide-img-la-lo"
                    src={require("../../img/i_3.png")}
                  />
                  <h5> Personalized Concierge Service </h5>
                  <p>
                    Let our dedicated concierge team curate bespoke experiences
                    tailored to your preferences
                  </p>
                </div>
                <div className="l-l-sec">
                  <img
                    className="slide-img-la-lo"
                    src={require("../../img/i_4.png")}
                  />
                  <h5> Exclusive Amenities </h5>
                  <p>
                    Immerse yourself in opulence with access to premium
                    facilities, private pools, stunning views, and more
                  </p>
                </div>
                <div className="l-l-sec">
                  <img
                    className="slide-img-la-lo"
                    src={require("../../img/i_5.png")}
                  />
                  <h5> Insider Recommendations </h5>
                  <p>
                    Discover Dubai 's hidden gems and insider tips through our
                    curated list of local attractions
                  </p>
                </div>
                <div className="l-l-sec">
                  <img
                    className="slide-img-la-lo"
                    src={require("../../img/i_6.png")}
                  />
                  <h5> 24 / 7 Customer Support </h5>
                  <p>
                    Rest easy knowing that our responsive and friendly team is
                    available round the clock to assist you
                  </p>
                </div>
              </div>
            </div>
            <div className="steps">
              <div className="steps-head">
                <div className="la-lo-se-one">
                  <h1> Why Staycia </h1>
                  <ul>
                    <li>
                      <strong> Unforgettable Experiences: </strong> Staycia
                      offers guests the opportunity to indulge in unforgettable
                      experiences during their stay in Dubai.From luxury
                      accommodations to personalized services, Staycia ensures a
                      memorable and extraordinary stay.
                    </li>
                    <li>
                      <strong> Prime Locations: </strong> Staycia's portfolio of
                      holiday homes is strategically located in prime
                      neighborhoods of Dubai, providing guests with easy access
                      to popular attractions, dining options, shopping centers,
                      and entertainment venues.
                    </li>
                    <li>
                      <strong> Hassle - Free Booking: </strong> With Staycia,
                      guests can enjoy a seamless and hassle - free booking
                      experience.The user - friendly website and intuitive
                      booking system make it easy to find and secure the perfect
                      holiday home for their stay in Dubai.
                    </li>
                    <li>
                      <strong> Exceptional Customer Service: </strong> Staycia
                      is dedicated to providing exceptional customer service to
                      its guests.The friendly and knowledgeable team is
                      available to assist guests throughout their stay, ensuring
                      their comfort and satisfaction.
                    </li>
                    <li>
                      <strong> Customized Stay Experience: </strong> Staycia
                      understands that each guest is unique, and their
                      preferences matter.The team goes the extra mile to tailor
                      the stay experience to guests ' specific needs, ensuring a
                      personalized and unforgettable stay.
                    </li>
                    <li>
                      <strong> Quality and Comfort: </strong> Staycia's holiday
                      homes are meticulously selected to provide guests with the
                      utmost quality, comfort, and luxury.Each property is
                      carefully inspected and maintained to ensure a high
                      standard of cleanliness, amenities, and overall guest
                      experience.
                    </li>
                    <li>
                      <strong> Value for Money: </strong> Staycia offers
                      competitive pricing and great value for money.Guests can
                      enjoy a luxurious stay with top - notch amenities and
                      services at a reasonable price point.
                    </li>
                    <li>
                      <strong> Local Expertise: </strong> Staycia's team has in
                      -depth knowledge of Dubai and can provide guests with
                      insider tips and recommendations on the best places to
                      visit, dine, shop, and explore, enhancing their overall
                      experience in the city.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="step-sec">
                <div className="step-card">
                  <div className="step-card-in">
                    <p> STEP 1 </p>
                    <div className="steps-num">
                      <img
                        className="slide-img-icon"
                        src={require("../../img/i_7.png")}
                      />
                    </div>
                    <h5> Discover and Select </h5>
                    <p>
                      Guests can explore Staycia 's collection of luxury holiday
                      homes in Dubai.They can easily browse through the
                      available properties, filtering their search based on
                      location, amenities, and preferences to find the ideal
                      accommodation
                    </p>
                  </div>
                </div>
                <div className="step-card">
                  <div className="step-card-in">
                    <p> STEP 2 </p>
                    <div className="steps-num">
                      <img
                        className="slide-img-icon"
                        src={require("../../img/i_8.png")}
                      />
                    </div>
                    <h5> Book and Customize </h5>
                    <p>
                      Once guests have chosen their desired property, they can
                      check its availability for their desired dates and make a
                      reservation.Staycia 's user-friendly booking system allows
                      guests to customize their stay by selecting additional
                      services or amenities, ensuring a tailored experience.
                    </p>
                  </div>
                </div>
                <div className="step-card">
                  <div className="step-card-in">
                    <p> STEP 3 </p>
                    <div className="steps-num">
                      <img
                        className="slide-img-icon"
                        src={require("../../img/i_9.png")}
                      />
                    </div>
                    <h5> Confirm and Enjoy </h5>
                    <p>
                      After reviewing the booking details, guests can proceed to
                      confirm their reservation and make a secure payment
                      online.Once the booking is confirmed, guests will receive
                      a confirmation email with all the necessary information
                      for their stay.They can now relax and enjoy their
                      luxurious stay, with the assurance that Staycia 's
                      dedicated team is available to assist them throughout
                      their experience
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className=" my-div"
        style={{
          maxWidth: "1100px",
        }}
      >
        <div className={isActive.id === "divTwo" ? `divTwo` : "divTwo d-none "}>
          <div>
            <div className="land-lord-card">
              <div className="la-lo-se-two">
                <div className="l-l-sec">
                  <img
                    className="slide-img-la-lo"
                    src={require("../../img/i_10.png")}
                  />
                  <h5> Personalized Approach </h5>
                  <p>
                    We take the time to understand your specific goals and
                    requirements as a homeowner.Our tailored solutions ensure
                    that our services align perfectly with your needs,
                    delivering exceptional results
                  </p>
                </div>
                <div className="l-l-sec">
                  <img
                    className="slide-img-la-lo"
                    src={require("../../img/i_11.png")}
                  />
                  <h5> Maximum Rental Income </h5>
                  <p>
                    Our proven strategies and pricing models are designed to
                    optimize your rental income.We stay up - to - date with
                    market trends and leverage our expertise to ensure
                    competitive pricing for your property
                  </p>
                </div>
                <div className="l-l-sec">
                  <img
                    className="slide-img-la-lo"
                    src={require("../../img/i_12.png")}
                  />
                  <h5> Hassle - Free Management </h5>
                  <p>
                    Say goodbye to the headaches of managing your property on
                    your own.Staycia takes care of everything, from guest
                    inquiries and bookings to check - ins, cleaning, and
                    maintenance
                  </p>
                </div>
                <div className="l-l-sec">
                  <img
                    className="slide-img-la-lo"
                    src={require("../../img/i_13.png")}
                  />
                  <h5> Marketing and Exposure </h5>
                  <p>
                    Our comprehensive marketing strategies are designed to
                    showcase your property to a wide audience, Benefit from our
                    extensive network, online presence, and targeted campaigns
                    to attract quality guests
                  </p>
                </div>
                <div className="l-l-sec">
                  <img
                    className="slide-img-la-lo"
                    src={require("../../img/i_14.png")}
                  />
                  <h5> Guest Screening and Vetting </h5>
                  <p>
                    We prioritise the safety and security of your property and
                    guests.Our thorough screening process ensures that only
                    reliable and trustworthy guests stay at your property
                  </p>
                </div>
                <div className="l-l-sec">
                  <img
                    className="slide-img-la-lo"
                    src={require("../../img/i_2.png")}
                  />
                  <h5> Transparent Communication and Reporting </h5>
                  <p>
                    Stay informed about your property 's performance with our
                    transparent reporting.We provide regular updates, financial
                    statements, and performance insights to keep you in the loop
                  </p>
                </div>
                <div className="l-l-sec">
                  <img
                    className="slide-img-la-lo"
                    src={require("../../img/i_16.png")}
                  />
                  <h5> Exceptional Guest Experiences </h5>
                  <p>
                    We strive to create memorable stays for guests, enhancing
                    their experience and leaving them satisfied.Positive guest
                    reviews and recommendations contribute to your property 's
                    success and reputation
                  </p>
                </div>
                <div className="l-l-sec">
                  <img
                    className="slide-img-la-lo"
                    src={require("../../img/i_17.png")}
                  />
                  <h5> Peace of Mind </h5>
                  <p>
                    With Staycia, you can enjoy peace of mind knowing that your
                    property is in capable hands.We handle the day - to - day
                    management and any issues that may arise, allowing you to
                    focus on other priorities
                  </p>
                </div>
              </div>
            </div>
            <div className="la-lo-se-one">
              <h1> Why Staycia </h1>
              <p>
                Benefit from our extensive knowledge and years of experience in
                the short - let management industry.Our team of experts
                understands the unique demands of the Dubai market and knows how
                to maximize your property 's potential.
              </p>
            </div>
            <div className="counter-wrap">
              <div className="photo-profile"> {/* <img  /> */} </div>
              <div className="numbers">
                <div className="num-con">
                  <Counter number={4.5} title="Stars Rating" />
                </div>
                <div className="num-con">
                  <Counter number={33} title="Properties Managed" />
                </div>
                <div className="num-con">
                  <Counter number={90} title="% Occupancy Rate" />
                </div>
                <div className="num-con">
                  <Counter number={4230} title="Happy Guests" />
                </div>
              </div>
            </div>
            <div className="steps">
              <div className="step-sec">
                <div className="step-card">
                  <div className="step-card-in">
                    <p> STEP 1 </p>
                    <div className="steps-num">
                      <img
                        className="slide-img-icon"
                        src={require("../../img/i_18.png")}
                      />
                    </div>
                    <h5> Consultation & Evaluation </h5>
                    <p>
                      Schedule an initial consultation to discuss your property
                      and goals.Our experts will conduct a thorough evaluation
                      to determine the best pricing and marketing strategy.
                    </p>
                  </div>
                </div>
                <div className="step-card">
                  <div className="step-card-in">
                    <p> STEP 2 </p>
                    <div className="steps-num">
                      <img
                        className="slide-img-icon"
                        src={require("../../img/i_19.png")}
                      />
                    </div>
                    <h5> Onboarding & Marketing </h5>
                    <p>
                      Once the evaluation is complete, we 'll handle the
                      necessary paperwork, including agreements for pricing,
                      payment, maintenance, and legal assistance.Our team of
                      professionals will also capture stunning photos and design
                      an inviting interior for your property.
                    </p>
                  </div>
                </div>
                <div className="step-card">
                  <div className="step-card-in">
                    <p> STEP 3 </p>
                    <div className="steps-num">
                      <img
                        className="slide-img-icon"
                        src={require("../../img/i_20.png")}
                      />
                    </div>
                    <h5> Ongoing Support & Success </h5>
                    <p>
                      With our ongoing support, your property will be well -
                      prepared for guests at all times.We 're committed to your
                      satisfaction and ensuring your property achieves its full
                      potential.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <Partners />
    </div>
  );
}
export default Why;
