import React from "react";
import contact from "../../img/conatct.jpg";

import "./contact.css";
import { Link } from "react-router-dom";
import HaddingBanner from "../../components/headingbanner/HaddingBanner";

const Contact = () => {
  return (
    <div className="pp-container">
      <div>
        <div
          style={{
            backgroundImage: `url(${contact})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className="page-head"
        >
          <h1> Contact </h1>
        </div>
      </div>
      <div
        className="contact-container"
        style={{
          maxWidth: "1200px",
        }}
      >
        <div className="contact-con-in">
          <h4> Reach out to us </h4>
          <div className="contact-con-in-text">
            <div className="cont-data"> </div>
            <div className="cont-data">
              <div>
                <img src={require("../../img/mail.png")} />
              </div>
              <div>
                <h5> info @staycia.com </h5>
              </div>
            </div>
            <div className="cont-data">
              <div>
                <img src={require("../../img/call.png")} />
              </div>
              <div>
                <h5> +971 58 525 7821</h5>
              </div>
            </div>
            <div className="cont-data">
              <div>
                <img src={require("../../img/whatapp.png")} />
              </div>
              <div>
                <h5> +971 56 374 5683</h5>
              </div>
            </div>
            <div className="cont-data">
              <div>
                <img src={require("../../img/home.png")} />
              </div>
              <div>
                <h5>
                  Dubai <br />
                  United Arab Emirates
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-con-in">
          <h4> Locate Us </h4>
          <div className="contact-con-in-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d57800.997632870116!2d55.2042496!3d25.116671999999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sae!4v1679762147008!5m2!1sen!2sae"
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
      <div
        className="contact-form-section"
        style={{
          maxWidth: "1200px",
        }}
      >
        <h4> Do you have a question ? Leave us a messege </h4>
        <div className="contact-form-container">
          <form className="reg-Form">
            <div className="form-grid">
              <div>
                <label className="form-label "> </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  defaultValue=""
                />
              </div>
              <div>
                <label className="form-label "> </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  defaultValue=""
                />
              </div>
              <div>
                <label className="form-label "> </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Email"
                  defaultValue=""
                />
              </div>
              <div>
                <label className="form-label "> </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Phone Number"
                  defaultValue=""
                />
              </div>
            </div>
            <div className="">
              <textarea
                placeholder="Message"
                className="form-control-text"
                name="message"
              ></textarea>
            </div>
            <button className="primary-btn"> send </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
