import React, { useState } from "react";
import "./property.scss";
import useFetch from "../../hooks/useFetch";
import { useParams } from "react-router-dom";
import Modal from "../../components/modal/Modal";
import Place from "../../components/place/Place";
import Gallery from "../../components/gallery/Gallery";

const Property = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPlaceOpen, setIsPlaceOpen] = useState(false);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openPlace = () => {
    setIsPlaceOpen(true);
  };

  const closePlace = () => {
    setIsPlaceOpen(false);
  };

  const openGallery = () => {
    setIsGalleryOpen(true);
  };

  const closeGallery = () => {
    setIsGalleryOpen(false);
  };

  const id = useParams().id;
  const { data, loading, error } = useFetch(`/properties/${id}?populate=*`);

  return (
    <>
      {loading ? (
        "Loading"
      ) : (
        <>
          <div className="property">
            <div className="gelary">
              <div className="hedtag">
                <h3>{data?.attributes?.title}</h3>
                <p>{data?.attributes?.location}</p>
              </div>
              <div className="show_all_wrap">
                <div className="gallry_sec">
                  <div className="section_one">
                    <div className="item" onClick={openGallery}>
                      <div className="item_wrap">
                        <img
                          className="img_one "
                          src={
                            process.env.REACT_APP_UPLOAD_URL +
                            data?.attributes?.main_img?.data?.attributes?.url
                          }
                          alt=""
                        />
                      </div>
                      <div className="item_over"></div>
                    </div>
                  </div>
                  <div className="section_two">
                    <div className="item" onClick={openGallery}>
                      <div className="item_wrap">
                        <img
                          src={
                            process.env.REACT_APP_UPLOAD_URL +
                            data?.attributes?.img2?.data?.attributes?.url
                          }
                          alt=""
                        />
                      </div>
                      <div className="item_over"></div>
                    </div>
                    <div className="item" onClick={openGallery}>
                      <div className="item_wrap ">
                        <img
                          className="img_two"
                          src={
                            process.env.REACT_APP_UPLOAD_URL +
                            data?.attributes?.img3?.data?.attributes?.url
                          }
                          alt=""
                        />
                      </div>
                      <div className="item_over"></div>
                    </div>

                    <div className="item" onClick={openGallery}>
                      <div className="item_wrap">
                        <img
                          src={
                            process.env.REACT_APP_UPLOAD_URL +
                            data?.attributes?.img4?.data?.attributes?.url
                          }
                          alt=""
                        />
                      </div>
                      <div className="item_over"></div>
                    </div>

                    <div className="item" onClick={openGallery}>
                      <div className="item_wrap">
                        <img
                          className="img_three"
                          src={
                            process.env.REACT_APP_UPLOAD_URL +
                            data?.attributes?.img5?.data?.attributes?.url
                          }
                          alt=""
                        />
                      </div>
                      <div className="item_over"></div>
                    </div>
                  </div>
                </div>
                <div className="show_all">
                  <button className="show_btn" onClick={openGallery}>
                    Show all photos
                  </button>
                  <Gallery
                    isOpen={isGalleryOpen}
                    closeGallery={closeGallery}
                  ></Gallery>
                </div>
              </div>
            </div>

            <div className="info_con">
              <div className="details_container">
                <div className="info_wrp_top">
                  <div className="info_wrp_in_top">
                    <div className="wrap_desc">
                      <h3>{data?.attributes?.subtitle}</h3>
                      <span>{data?.attributes?.subtitle2}</span>
                    </div>
                    <div className="wrap_desc">
                      <img
                        src={require("../../img/logoS.webp")}
                        alt="Staycia_img"
                      />
                    </div>
                  </div>
                </div>
                <hr />
                <div className="info_wrp_two">
                  <div className="info_sec">
                    <div className="svg__">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon1?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="svg_text">
                      <h5>{data?.attributes?.icon1_title}</h5>
                      <p>{data?.attributes?.icon1_subtitle}</p>
                    </div>
                  </div>

                  <div className="info_sec">
                    <div className="svg__">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon2?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="svg_text">
                      <h5>{data?.attributes?.icon2_title}</h5>
                      <p>{data?.attributes?.icon2_subtitle}</p>
                    </div>
                  </div>

                  <div className="info_sec">
                    <div className="svg__">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          data?.attributes?.icon3?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="svg_text">
                      <h5>{data?.attributes?.icon3_title}</h5>
                      <p>{data?.attributes?.icon3_subtitle}</p>
                    </div>
                  </div>

                  <div className="_info">
                    <p>{data?.attributes?.desc}</p>
                    <h6>The space....</h6>
                    <button onClick={openModal}>Show more</button>
                    <Modal isOpen={isModalOpen} closeModal={closeModal}></Modal>
                  </div>
                </div>
                <hr />
                <div className="info_wrp">
                  <h3>What this place offers</h3>
                  <br />
                  <div className="list_">
                    <div className="__list_offr">
                      <div className="list_item_div">
                        <div className="list_icon">
                          <img
                            src={
                              process.env.REACT_APP_UPLOAD_URL +
                              data?.attributes?.icon4?.data?.attributes?.url
                            }
                          />
                        </div>
                        <div className="list_text">
                          <h5>{data?.attributes?.icon4_title}</h5>
                          <p>{data?.attributes?.icon4_subtitle}</p>
                        </div>
                      </div>

                      <div className="list_item_div">
                        <div className="list_icon">
                          <img
                            src={
                              process.env.REACT_APP_UPLOAD_URL +
                              data?.attributes?.icon5?.data?.attributes?.url
                            }
                          />
                        </div>
                        <div className="list_text">
                          <h5>{data?.attributes?.icon5_title}</h5>
                          <p>{data?.attributes?.icon5_subtitle}</p>
                        </div>
                      </div>

                      <div className="list_item_div">
                        <div className="list_icon">
                          <img
                            src={
                              process.env.REACT_APP_UPLOAD_URL +
                              data?.attributes?.icon6?.data?.attributes?.url
                            }
                          />
                        </div>
                        <div className="list_text">
                          <h5>{data?.attributes?.icon6_title}</h5>
                          <p>{data?.attributes?.icon6_subtitle}</p>
                        </div>
                      </div>

                      <div className="list_item_div">
                        <div className="list_icon">
                          <img
                            src={
                              process.env.REACT_APP_UPLOAD_URL +
                              data?.attributes?.icon7?.data?.attributes?.url
                            }
                          />
                        </div>
                        <div className="list_text">
                          <h5>{data?.attributes?.icon7_title}</h5>
                          <p>{data?.attributes?.icon7_subtitle}</p>
                        </div>
                      </div>

                      <div className="list_item_div">
                        <div className="list_icon">
                          <img
                            src={
                              process.env.REACT_APP_UPLOAD_URL +
                              data?.attributes?.icon8?.data?.attributes?.url
                            }
                          />
                        </div>
                        <div className="list_text">
                          <h5>{data?.attributes?.icon8_title}</h5>
                          <p>{data?.attributes?.icon8_subtitle}</p>
                        </div>
                      </div>

                      <div className="list_item_div">
                        <div className="list_icon">
                          <img
                            src={
                              process.env.REACT_APP_UPLOAD_URL +
                              data?.attributes?.icon9?.data?.attributes?.url
                            }
                          />
                        </div>
                        <div className="list_text">
                          <h5>{data?.attributes?.icon9_title}</h5>
                          <p>{data?.attributes?.icon9_subtitle}</p>
                        </div>
                      </div>
                      <div className="list_item_div">
                        <div className="list_icon">
                          <img
                            src={
                              process.env.REACT_APP_UPLOAD_URL +
                              data?.attributes?.icon10?.data?.attributes?.url
                            }
                          />
                        </div>
                        <div className="list_text">
                          <h5>{data?.attributes?.icon10_title}</h5>
                          <p>{data?.attributes?.icon10_subtitle}</p>
                        </div>
                      </div>

                      <div className="list_item_div">
                        <div className="list_icon">
                          <img
                            src={
                              process.env.REACT_APP_UPLOAD_URL +
                              data?.attributes?.icon11?.data?.attributes?.url
                            }
                          />
                        </div>
                        <div className="list_text">
                          <h5>{data?.attributes?.icon11_title}</h5>
                          <p>{data?.attributes?.icon11_subtitle}</p>
                        </div>
                      </div>

                      <div className="list_item_div">
                        <div className="list_icon">
                          <img
                            src={
                              process.env.REACT_APP_UPLOAD_URL +
                              data?.attributes?.icon12?.data?.attributes?.url
                            }
                          />
                        </div>
                        <div className="list_text">
                          <h5>{data?.attributes?.icon12_title}</h5>
                          <p>{data?.attributes?.icon12_subtitle}</p>
                        </div>
                      </div>

                      <div className="list_item_div">
                        <div className="list_icon">
                          <img
                            src={
                              process.env.REACT_APP_UPLOAD_URL +
                              data?.attributes?.icon13?.data?.attributes?.url
                            }
                          />
                        </div>
                        <div className="list_text">
                          <h5>{data?.attributes?.icon13_title}</h5>
                          <p>{data?.attributes?.icon13_subtitle}</p>
                        </div>
                      </div>
                    </div>
                    <br />

                    <button className="offer_btn" onClick={openPlace}>
                      Show all 30 amenities
                    </button>
                    <Place isOpen={isPlaceOpen} closePlace={closePlace}></Place>
                  </div>
                </div>

                <hr />
                <div className="map">
                  <h3>Where you’ll be</h3>
                  <p>{data?.attributes?.location}</p>
                  <div>
                    <iframe src={data?.attributes?.googlemap}></iframe>
                  </div>
                </div>
              </div>
              <div className="price_container">
                <div className="price_sec">
                  <div className="item">
                    <button className="primary-btn_two">
                      <a target="_blank" href={data?.attributes?.weburl}>
                        Check availability
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Property;
