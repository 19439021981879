import React from "react";

import "./policy.css";
import HaddingBanner from "../../components/headingbanner/HaddingBanner";

const Cancellation = () => {
  return (
    <div className="pp-container">
      <HaddingBanner hedding="Cancellation" />
      <div
        className="polocy-info"
        style={{
          maxWidth: "1200px",
        }}
      >
        <div className="po-info-con">
          <p>
            Reservations with prepayment will not be subject to any changes and
            / or cancellations.The prepayment(money paid in advance) will not be
            reimbursed.
          </p>
          <span> Special Conditions allow: </span>
          <ul>
            <li>
              The cancellation of the reservation or a Service can be requested
              only with reservations @staycia.com
            </li>
            <li>
              The modification of the reservation of a Service can be done
              directly with the reservations team, whose contact information,
              including telephone number, is specified on the reservation
              confirmation e - mail.
            </li>
          </ul>
          <p>
            In the event of an interruption of a Service or cancellation by the
            Guest, the entire agreed - upon price will be charged.No refund will
            be granted in the event of a reservation with pre - payment prior to
            the stay. <br />
            <br />
            In the case ofan Accommodation Service, the Guest must leave the
            unit before the time indicated by staycia, generally 12 noon on the
            last day of the reservation.Otherwise, the Guest will be charged for
            an additional night.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Cancellation;
