import React from "react";
import "./service.css";

import Airport from "../../img/airport.jpeg";
import Car from "../../img/car.jpeg";
import Med from "../../img/mad.jpeg";
import food from "../../img/food.jpeg";
import cook from "../../img/cook.jpeg";
import massage from "../../img/massage.jpeg";
import club from "../../img/club.jpeg";
import water from "../../img/water.jpeg";
import adventure from "../../img/adventure.jpeg";
import yacht from "../../img/yacht.jpeg";
import yoga from "../../img/yoga.jpeg";
import HaddingBanner from "../../components/headingbanner/HaddingBanner";
import Stayupdate from "../../components/stayupdate/Stayupdate";

const Service = () => {
  return (
    <div className="ser-container">
      <HaddingBanner hedding="Services" />

      <div
        style={{
          maxWidth: "1200px",
        }}
      >
        <div className="section-service-container">
          <div className="sec-ser-con-text">
            <div>
              <h2> Airport transfers </h2>
            </div>
            <p>
              We can provide a customized service to suit your taste and
              budget.Whether limousine, chauffeur driven 4 x 4, luxury car or
              VIP service– haus & haus can cater for your every need.Our
              priority is impeccable timing, your comfort, safety and providing
              a fleet of vehicles which satisfy your demands– whatever they
              are!We are happy to provide quotes on a variety of services, no
              matter how big or small.Try us and see…
            </p>
          </div>
          <div className="sec-ser-con-img">
            <div
              className="ser-sec-img"
              style={{
                backgroundImage: `url(${Airport})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
          </div>
        </div>
        <div className="section-service-container-color">
          <div className="sec-ser-con-img">
            <div
              className="ser-sec-img"
              style={{
                backgroundImage: `url(${Car})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
          </div>
          <div className="sec-ser-con-text">
            <div>
              <h2> Car rental </h2>
            </div>
            <p>
              Our team can offer you any rental car to match your pockets, taste
              and budget for your stay here in Dubai.One phone call to our
              experienced team is all that is needed– we will do the rest.
              Whether an economy run - around, all the way up to a Lamborghini
              Aventador(and beyond)– we can tailor almost any demand, for any
              timeframe.All of our providers are from the most reputable
              companies in Dubai.We only work with the best and those that offer
              competitive rates– forget the headache and hassle of trying to do
              research yourself.We have been living and operating here for years
              and will make the process simple.Insurance will cover your hire on
              the total duration for your peace of mind.
            </p>
          </div>
        </div>
        <div className="section-service-container">
          <div className="sec-ser-con-text">
            <div>
              <h2> Daily maid services </h2>
            </div>
            <p>
              All of our short term lets have allocated cleans dependent upon
              the duration of stay.We can tailor this(at an additional charge)
              for those people that literally don’ t want to‘ lift a finger’
              whilst on holiday.We can customize packages for certain times of
              day, multiple daily cleans and nightly dress down services, or
              after specific events or parties.Give us a call– we are happy to
              talk, whatever your enquiry!
            </p>
          </div>
          <div className="sec-ser-con-img">
            <div
              className="ser-sec-img"
              style={{
                backgroundImage: `url(${Med})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
          </div>
        </div>
        <div className="section-service-container-color">
          <div className="sec-ser-con-img">
            <div
              className="ser-sec-img"
              style={{
                backgroundImage: `url(${food})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
          </div>
          <div className="sec-ser-con-text">
            <div>
              <h2> Groceries upon arrival </h2>
            </div>
            <p>
              You know the feeling.A late night arrival or just not wanting to
              hit the supermarket as soon as you arrive!Our professional team
              can handpick produce for when you first put the keys in the
              door.Whether this is a full blown silver service meal, basket of
              fruit, drinks and meals to prep, or just the necessities.Relax,
              you’ re on holiday!Just give us a call…
            </p>
          </div>
        </div>
        <div className="section-service-container">
          <div className="sec-ser-con-text">
            <div>
              <h2> Private chef for breakfast, lunch and dinner </h2>
            </div>
            <p>
              We have access to some of Dubai’ s best chefs.Beyond that we have
              some of the most famed chefs worldwide in our call list!Whether
              you are looking to impress a lucky someone, a dinner party looking
              over the Arabian Gulf or something a little less formal, we have
              it covered!Our friendly team have had some unusual, and mind -
              blowing requests over the years– surprise us!Your total
              satisfaction is our ambition.
            </p>
          </div>
          <div className="sec-ser-con-img">
            <div
              className="ser-sec-img"
              style={{
                backgroundImage: `url(${cook})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
          </div>
        </div>
        <div className="section-service-container-color">
          <div className="sec-ser-con-img">
            <div
              className="ser-sec-img"
              style={{
                backgroundImage: `url(${massage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
          </div>
          <div className="sec-ser-con-text">
            <div>
              <h2> Massage and treatments </h2>
            </div>
            <p>
              Whether in -haus massage or external spa packages, cosmetic
              surgery or health treatments– our team of advisors can arrange a
              visit to a suitable practice, or a package to suit.We know the
              best deals, the finest facilities and those places that will
              pamper you the max!We can even provide a drop off and collection
              service once you are done.If you do not wish to move from your
              living room or balcony we can even arrange for any therapist, mind
              or body coach to visit you.Relax, we got you covered!
            </p>
          </div>
        </div>
        <div className="section-service-container">
          <div className="sec-ser-con-text">
            <div>
              <h2> Pass for beach clubs </h2>
            </div>
            <p>
              Let us take your holiday to the next level with an exclusive rate
              for entry into some of the most glamorous and enticing beach clubs
              in the UAE.You can think of the Beach Club Pass as a golden
              ticket!It allows you to enjoy unlimited weekday access to the
              spectacular beach clubs at Atlantis The Palm, Waldorf Astoria The
              Palm, W The Palm, The Westin Dubai Mina Seyahi, Sheraton Jumeirah
              Beach Resort, DoubleTree Hilton JBR– and more.So, for your whole
              stay you can go for dips in Dubai’ s most impressive pools, unwind
              on pristine private beaches, or burn of energy at state - of -the
              - art gyms.In addition to using these gorgeous facilities, you
              will also get up to 50 % off dining and spa experiences.Be
              prepared for your Instagram account to explode… GET YOUR BEACH
              CLUB PASS
            </p>
          </div>
          <div className="sec-ser-con-img">
            <div
              className="ser-sec-img"
              style={{
                backgroundImage: `url(${club})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
          </div>
        </div>
        <div className="section-service-container-color">
          <div className="sec-ser-con-img">
            <div
              className="ser-sec-img"
              style={{
                backgroundImage: `url(${water})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
          </div>
          <div className="sec-ser-con-text">
            <div>
              <h2> Watersport activities </h2>
            </div>
            <p>
              Dubai has the most diverse array of watersports.In holiday season
              the beach, surroundings, amenities and Gulf Sea offers one of the
              best places on Earth to do your thing!Kite surfing, scuba diving,
              kayaking, jet - skiing, wakeboarding, surfing, paragliding– get
              the jist ? These are just the appetizers… What better way to top
              up that tan, than in the waves!If kids need to be entertained we
              have plenty of unusual, exciting and boredom free activities to
              take them off your hands!Memories last a lifetime.
            </p>
          </div>
        </div>
        <div className="section-service-container">
          <div className="sec-ser-con-text">
            <div>
              <h2> Desert adventures </h2>
            </div>
            <p>
              They say‘ When in Rome.’Well, ‘When in Dubai’ you just have to do
              a desert safari!Our close ties with some of the longest standing,
              most trusted, and best run desert safari tours means total
              satisfaction for you.With pick - up and drop off as standard,
              food, belly dancing, sand boarding, henna painting and camel
              riding included in the price, you will feel the true traditional
              Arabian charm this adventure will give.
            </p>
          </div>
          <div className="sec-ser-con-img">
            <div
              className="ser-sec-img"
              style={{
                backgroundImage: `url(${adventure})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
          </div>
        </div>
        <div className="section-service-container-color">
          <div className="sec-ser-con-img">
            <div
              className="ser-sec-img"
              style={{
                backgroundImage: `url(${yacht})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
          </div>
          <div className="sec-ser-con-text">
            <div>
              <h2> Yacht hire </h2>
            </div>
            <p>
              Party, family event, wedding or special occasion.Day or night.We
              have an army of yachts at your disposal– from 2 - 3 people up to
              500 guests!Our 5 * service will not disappoint– we have access to
              some internationally renowned DJ’ s as well professional catering
              and hostess services if you want that finishing touch to your
              celebration.When in Dubai, why not ? Yalla!
            </p>
          </div>
        </div>
        <div className="section-service-container">
          <div className="sec-ser-con-text">
            <div>
              <h2> Private yoga or personal trainer </h2>
            </div>
            <p>
              If‘ Zen’ or keeping fit is your thing, haus & haus can keep you in
              shape even whilst on your holidays!We have a team of professionals
              who can push, pull, stretch and relax you.Or you pick which parts
              take your fancy, and we will organize tailored sessions.Yoga on
              the beach– no problem.Aqua aerobics– we’ ve got it.Pilates under
              the stars– we can arrange.Speak to us today and tell us what
              tickles your fancy.
            </p>
          </div>
          <div className="sec-ser-con-img">
            <div
              className="ser-sec-img"
              style={{
                backgroundImage: `url(${yoga})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
          </div>
        </div>
      </div>

      <Stayupdate />
    </div>
  );
};

export default Service;
