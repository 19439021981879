import React from "react";
import "./app.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Service from "./pages/service/Service";
import PrivacyPolicy from "./pages/policys/PrivacyPolicy";
import Refund from "./pages/policys/Refund";
import Cancellation from "./pages/policys/Cancellation";
import Contact from "./pages/contact/Contact";
import Locations from "./pages/locations/Locations";
import ListProperty from "./pages/listProperty/ListProperty";
import PartnerWithUs from "./pages/listProperty/PartnerWithUs";
import SearchResults from "./pages/searchresults/SearchResults";
import About from "./pages/about/About";
import Why from "./pages/why/Why";
import LandLord from "./pages/landlord/LandLord";
import Guest from "./pages/landlord/Guest";
import Errorpage from "./pages/Error/Errorpage";
import Whatapp from "./components/whatapp/Whatapp";
import Footer from "./components/footer/Footer";
import Navbar from "./components/navbar/Navbar";
import Home from "./pages/home/Home";
import Property from "./pages/property/Property";
import Properties from "./pages/Properties/Properties";


const App = () => {
  return (
    <BrowserRouter
      style={{
        maxWidth: "650px",
      }}
    >
      <Navbar />
      <Whatapp />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/service" element={<Service />} />
        <Route exact path="/privacy" element={<PrivacyPolicy />} />
        <Route exact path="/payment-and-refund" element={<Refund />} />
        <Route exact path="/cancellation" element={<Cancellation />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/locations" element={<Locations />} />
        <Route exact path="/list-property" element={<ListProperty />} />
        <Route exact path="/partner-with-us" element={<PartnerWithUs />} />
        <Route exact path="/search-results" element={<SearchResults />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/why" element={<Why />} />
        <Route exact path="/landlord" element={<LandLord />} />
        <Route exact path="/guest" element={<Guest />} />
        <Route exact path="*" element={<Errorpage />} />
        <Route exact path="/property/:id" element={<Property />} />
        <Route exact path="/properties/:id" element={<Properties/>} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
