import React from "react";
import "./listPropaty.css";
import { Link } from "react-router-dom";

const ListPropaty = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="main-container">
      <div className="list-propaty-wrap">
        <div
          className="list-propaty "
          style={{
            maxWidth: "1200px",
          }}
        >
          <div className="list-contents">
            <span> List your property with us </span>
            <h4>
              Maximize rental income and enjoy peace of mind with Staycia.
            </h4>
            <p>
              Our trusted management services ensure a seamless experience
              giving you peace of mind <br />
              while we take care of everything for you.
            </p>
            <Link to="/Contact">
              <button onClick={scrollToTop} className="pro-btn">
                {" "}
                More....{" "}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListPropaty;
