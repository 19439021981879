import React from "react";

import "./policy.css";
import HaddingBanner from "../../components/headingbanner/HaddingBanner";

const PrivacyPolicy = () => {
  return (
    <div className="pp-container">
      <HaddingBanner hedding="privacy policy" />
      <div
        className="polocy-info"
        style={{
          maxWidth: "1200px",
        }}
      >
        <div className="po-info-con">
          <p>
            This staycia privacy notice('Privacy Notice') describes how
            staycia('we' or 'us') collects, uses, shares, and retains personal
            information that you provide to us, or that we collect, when you use
            the staycia website located at https: //www.staycia.com and other
            websites owned or controlled by staycia(the 'sites') or related
            mobile applications, complete related forms, participate in staycia
            events, or communicate with one of our customer service
            representatives('personal data'). <br />
            <br />
            Please note that if you disagree with anything in this privacy
            notice, you must not use the sites or mobile applications, or
            provide personal information to us in connection with staycia 's
            products or services.
          </p>
          <h5> Table of contents: </h5>
          <ul>
            <li> 01. Collection of personal information </li>
            <li> 02. Marketing communication </li>
            <li> 03. Use, sharing and retention of personal information </li>
            <li>
              04. How to contact staycia and modify your information or
              preferences
            </li>
            <li> 05. Modifications to this privacy notice </li>
            <li> 06. Questions, concerns or complaints </li>
          </ul>
          <section>
            <span> 01. Collection of personal information </span>
            <span>
              a.Information you directly and voluntarily provide to us
            </span>
            <p>
              <span> Enquiry: </span> If you provide your personal data to
              staycia to enquire about properties or events, or if you sign up
              to become a registered user of any website operated by staycia,
              you will be required to provide certain information as part of the
              registration process.This information may include your first and
              last name, email address, telephone number and business or home
              address. <br />
              <br />
              Membership information is used to communicate with you, to design
              content and activities that we believe would be of interest to
              you, and to ensure that staycia will not violate any applicable
              sanctions in providing you access to staycia 's goods and
              services. If you wish to opt - out of receiving these
              communications from us, please follow the instructions contained
              in an applicable communication you receive from us. <br />
              <br />
              <span> Events: </span> staycia may host events for product
              knowledge sharing.If you register for a staycia event, we will
              access the information in your account to provide you with
              information and services associated with the event.If you register
              for one of our events and you are not a staycia member, we will
              collect your first and last name, email address, business or home
              address, information about the type of business you work for or
              with, which we will use to provide you with information and
              services associated with the event. <br />
              <br />
              <span> Communications with staycia: </span> If you communicate or
              correspond with us by email, through postal mail, via phone or
              through other forms of communication, including our customer
              service centre, we may collect the information you provide as part
              of those communications.For example, if you correspond with us
              through email, we may collect and store the email address you use
              to send the applicable correspondence and use it to respond to
              your enquiry; to notify you of staycia events, or other services;
              or to keep a record of your complaint, accommodation request, and
              similar purposes.
            </p>
            <span> a.Information we automatically collect from you </span>
            <p>
              We may automatically collect information about you when you use
              the sites or our services.For example, if you access the sites
              through a computer, we will automatically collect information such
              as your browser type and version, computer and connection
              information, IP address, pages you have visited and standard web
              log information.We may use remarketing tools that will serve ads
              to you based on your location, the content you have explored on
              our site to render ads to you after you have left our site.If you
              access the sites through a mobile device, we may also be able to
              identify the location of your mobile device.You may choose not to
              share your location details with us by adjusting your mobile
              device 's location services settings. For instructions on changing
              the relevant settings, please contact your service provider or
              device manufacturer. <br />
              We may automatically collect information from you when you use the
              sites using 'cookies' and other similar technologies, such as web
              beacons.Cookies are small amounts of data that are stored within
              your computer 's internet browser and that are accessed and
              recorded by the websites that you visit so that they can recognise
              the same browser navigating online at a later time.The cookies are
              not able to execute code or access other information stored on the
              computer.Web beacons are transparent pixel images that are used in
              collecting information about website usage, email response and
              tracking. <br />
              In addition to those mentioned above, third parties may also set
              cookies when you visit the site.In some cases, that is because we
              have hired a third party to provide services on our behalf, such
              as an enquiry form.Because your browser connects to those third
              parties, they are able to set or read their own cookies on your
              device and may collect information about your online
              activities(but not any personally identifiable data) across
              websites or online services.Information that may be collected by
              cookies when you use the sites may include, without limitation:
            </p>
            <ul>
              <li> the pages you visit within the sites </li>
              <li> the date and time of your visit to the sites </li>
              <li> the amount of time you spend using the sites </li>
              <li>
                the Internet Protocol(IP) address used to connect your computer
                to the internet
              </li>
              <li>
                your computer and connection information such as your browser
                type and version, operating system and platform
              </li>
            </ul>
            <p>
              This information is collected to enhance the site performance and
              end user experience.You can set your browser to reject cookies or
              to notify you when you are sent a cookie.To learn more about your
              ability to manage cookies and web beacons, please consult the
              privacy features in your browser.
            </p>
            <span> Information you provide to payment processors </span>
            <p>
              All payments made to staycia are processed by third party
              processing service engaged by staycia.All information collected by
              these third - party providers for purposes of processing your
              payments is not availabel to us, unless you have otherwise
              provided this information to us in connection with your use of the
              sites or our products and services.
            </p>
            <span> 2. Marketing communication </span>
            <p>
              We may combine your information with data we collect from our
              vendors and use it to improve and personalise the staycia
              website.If you don 't wish to receive marketing communications
              from us or participate in our ad - customisation programs, simply
              update your communication preferences at info @staycia.com, or
              follow the directions provided with the communication or
              advertisement.
            </p>
            <span> Use, sharing and retention of personal information </span>
            <span> a.How we use your information </span>
            <p>
              In general, we use the information we collect primarily to
              provide, maintain, protect, and improve our current products and
              to develop new ones.This allows us to:
            </p>
            <ul>
              <li>
                Facilitate the creation of and security of your account on our
                network
              </li>
              <li> Identify you as a user in our services </li>
              <li>
                Improve our services, website and how we operate our business
              </li>
              <li>
                Understand and enhance your experience using our website,
                products and related services
              </li>
              <li>
                Respond to your comments or questions(and for our support team
                to provide service)
              </li>
              <li>
                Send you related information, including confirmations, invoices,
                technical notices, updates, security alerts and support and
                administrative messages
              </li>
              <li>
                Communicate with you about promotions, upcoming events and news
                regarding products and services offered by staycia
              </li>
              <li>
                Link or combine it with other information we get from third
                parties, to help us understand your needs and provide you with
                better service
              </li>
              <li>
                Protect, investigate and deter against fraudulent, unauthorised
                or illegal activity
              </li>
            </ul>
            <span>
              b.Information for individuals located in the European Union
            </span>
            <span>
              Legal basis for the processing of personal information from EEA
              residents
            </span>
            <p>
              If you reside within the European Economic Area(EEA), our
              processing of your personal information will be legitimised as
              follows:
            </p>
            <ul>
              <li>
                Whenever we require your consent for the processing of your
                personal information such processing will be justified pursuant
                to Article 6(1) lit(a) of the General Data Protection
                Regulation(EU) 2016 / 679('GDPR').This article in the GDPR
                describes when processing can be done lawfully.
              </li>
              <li>
                If the processing of your personal data is necessary for the
                performance of a contract between you and staycia or for taking
                any pre - contractual steps upon your request, such processing
                will be based on GDPR Article 6(1) lit(b).If this data is not
                processed, staycia will not be able to execute the contract with
                you.
              </li>
              <li>
                Where the processing is necessary for us to comply with a legal
                obligation, we will process your information on basis of GDPR
                Article 6(1) lit(c), for example complying in the fields of
                employment law.
              </li>
              <li>
                And where the processing is necessary for the purposes of
                staycia 's legitimate interests, such processing will be made in
                accordance with GDPR Article 6(1) lit(f), for example to detect
                fraud.
              </li>
              <li>
                You may also receive personalised advertising where you indicate
                to staycia specific interests by requesting information about a
                product or service or by indicating your marketing preferences
                in the Preference Centre(access to which is provided at the
                bottom of this page), however, you may also receive general
                communications regarding new launches, promotions or schemes.
              </li>
            </ul>
            <span> Transferring personal data from the EU to the UAE </span>
            <p>
              staycia has its headquarters in the United Arab
              Emirates(UAE).Information we collect from you will be processed in
              the United Arab Emirates.The security of your privacy and the
              information you give us is very important to us, but we cannot
              ensure or warrant the security of any information you transmit to
              staycia or guarantee that your information on the staycia Service
              may not be accessed, disclosed, altered or destroyed by breach of
              any of our industry standard physical, technical or managerial
              safeguards.When you enter sensitive information(such as log in
              credentials) on our order form or chat form, we encrypt that
              information using secure socket layer technology(SSL).Please be
              aware that no data transmission over the Internet can be
              guaranteed to be 100 % secure.As a result, staycia cannot
              guarantee or warrant the security of any information you transmit
              on or through the sites and you do so at your own risk. <br />
              staycia participates in its compliance with the EU Privacy Shield
              Framework.staycia is committed to subjecting all personal
              information received from EU member countries to the Frameworks '
              applicable principles('Principles').
            </p>
            <span> European Union data subject rights </span>
            <p>
              The European Union 's General Data Protection Regulation and other
              countries ' privacy laws provide certain rights for data subjects
              (these are persons that can be identified). <br />
              This Privacy Notice is intended to provide you with information
              about what personal data staycia collects about you and how it is
              used. <br />
              If you wish to confirm that staycia is processing your personal
              data, or to have access to the personal data staycia may have
              about you, or have other questions, please contact us at info
              @staycia.com <br />
              You may also request information through our Data Subject Access
              Portal about:
            </p>
            <ul>
              <li> the purpose of the processing </li>
              <li> the categories of personal data concerned </li>
              <li>
                who else outside staycia might have received the data from
                staycia
              </li>
              <li>
                what the source of the information was( if you did not provide
                it directly to staycia)
              </li>
              <li>
                where the personal data is stored and how long it will be stored
              </li>
            </ul>
            <p>
              You have a right to correct(rectify) the record of your personal
              data maintained by staycia if it is inaccurate.You may request
              that staycia erase that data or cease processing it, subject to
              certain exceptions.You may also ask staycia for your personal data
              to be supplemented or updated, or for their transformation into
              anonymous format or to block any data held in violation of the
              law, as well as to oppose their treatment for any and all
              legitimate reasons.You may withdraw your consent for the
              processing of personal data or the further processing of personal
              data by staycia at any time.You may also request that staycia
              cease using your data for Direct Marketing purposes by emailing
              info @staycia.com <br />
              <br />
              In many countries(including EEA countries), you have a right to
              lodge a complaint with the appropriate data protection authority
              if you have concerns about how staycia processes your personal
              data.When technically feasible, staycia will– at your request–
              provide your personal data to you or transmit it directly to
              another controller.You have the right to receive your personal
              information in a structured and standard format. <br />
              <br />
              In addition to the information contained in this Privacy Notice,
              you may be provided with additional and contextual information
              concerning particular services or the collection and processing of
              your personal data upon request.
            </p>
            <span> c.Data storage and retention </span>
            <p>
              Your personal data is stored by staycia on its servers, and on the
              servers of the database management services staycia engages,
              located in the United Arab Emirates.staycia retains data for the
              duration of the customer’ s business relationship with staycia and
              otherwise as required under applicable law.Personal data will be
              kept for no longer than is necessary for the purposes for which
              your personal data are processed.We will retain your personal data
              as long as you are a staycia customer or require our services so
              that we can provide these services to you.
            </p>
            <p>
              If you are located in the European Economic Area, at the moment
              you come out of staycia association or withdraw your consent for
              the processing of your personal information, all your personal
              data received and stored will be erased if no longer needed by
              us.Unless we are required to retain this personal data by law or
              to comply with our regulatory obligations.In such a case, we will
              only keep this personal data for as long as necessary.For more
              information on where and how long your personal data is stored,
              and for more information on your rights of erasure and
              portability, please contact our data privacy resource at info
              @staycia.com.
            </p>
            <span>
              4. How to contact staycia and modify your information or
              preferences
            </span>
            <p>
              Questions regarding this Privacy Notice should be directed to
              staycia’ s data privacy resource: info @staycia.com.If you would
              like to modify the types of marketing email messages you receive
              from staycia, you may do so by following the instructions within
              the body of any email message that you receive from us.
            </p>
            <span> 5. Modifications to this privacy notice </span>
            <p>
              From time to time, staycia may need to update or modify this
              Privacy Notice, to reflect changes in our business practices, data
              collection practices or organisation.We reserve the right to amend
              this Privacy Notice at any time, for any reason, without notice to
              you, other than the posting of the amended Privacy Notice on the
              sites, or, if you have provided your email address to us, sending
              you an email notifying you of the amended Privacy Notice.It is
              strongly recommended to check the site often, referring to the
              date of the last modification listed at the top.We will in any
              case not reduce your rights under this Privacy Notice without your
              explicit and informed consent.If you do not agree to the changes,
              you should discontinue your use of the sites, and cease providing
              personal information to us, prior to the time the modified Privacy
              Notice takes effect.If you continue using the sites or provide
              personal information after the modified Privacy Notice takes
              effect, you will be bound by the modified Privacy Notice.
            </p>
            <spna> 6. Questions, concerns or complaints </spna>
            <p>
              If you have any questions or concerns about this Privacy Notice,
              please contact info @staycia.com.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
