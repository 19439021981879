import React from "react";
import "./whatapp.css";

const Whatapp = () => {
  return (
    <div>
      <a href="https://wa.me/971563745683  " target="_blank">
        <div className="whatapp-icon">
          <span className="what-text"> Need Help ? Chat with us </span>
          <img src={require("../../img/WhatsApp-Logo.wine.png")} alt="Staycia_img" />
        </div>
      </a>
    </div>
  );
};

export default Whatapp;
