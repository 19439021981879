import React from "react";
import "./featured.css";
import Card from "../Card/Card";
import useFetch from "../../hooks/useFetch";

const Featured = ({ type }) => {
  const { data, loading, error } = useFetch(
    `/properties?populate=*&[filters][type][$eq]=${type}`
  );

  return (
    <div class="main-container">
      <div class="featured-con-main" style={{ maxWidth: "1000px" }}>
        <div>
          <h3>Stay at our top unique properties</h3>
          <p>Hand picked from our Staycia team, just for you</p>
        </div>
        <div class="featured-con">
          {error
            ? "Data Loading Error"
            : loading
            ? "Loading"
            : data?.map((item) => <Card item={item} key={item.id} />)}
        </div>
      </div>
    </div>
  );
};

export default Featured;
