import React, { useState } from "react";
import "./Card.scss";
import { Link } from "react-router-dom";

const Card = ({ item }) => {
  return (
    <Link to={`/property/${item.id}`}>
      <div class="featured-card">
        <div className="card_in">
          <img
            src={
              process.env.REACT_APP_UPLOAD_URL +
              item.attributes?.main_img?.data?.attributes?.url
            }
            alt=""
          />
        </div>
        <div class="featured-text">
          <span class="ft-head-text">{item?.attributes.title}</span>
          <span class="ft-sub-text">{item?.attributes?.location}</span>
        </div>
      </div>
    </Link>
  );
};

export default Card;
