import React from "react";
import "./navbar.css";
import { Link } from "react-router-dom";


const Navbar = () => {
  return (
    <div className="navbar_con">
      <nav class="navbar navbar-expand-lg bg-body-tertiary">
        <div class="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src={require("../../img/logo.png")} alt="Staycia_img" />
          </Link>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link " to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link " to="/">
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link " to="/properties/1">
                  Properties
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/why">
                  Why Staycia
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link " to="/service">
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
