import React from "react";
import "./attraction.css";

const Attraction = () => {
  return (
    <div className="main-container">
      <div
        className="attraction-con"
        style={{
          maxWidth: "1000px",
        }}
      >
        <div className="att-text">
          <h1> Explore our amazing selection of stays and indulge... </h1>
          <h5> Unforgettable attractions in Dubai </h5>
        </div>
        <div className="att-sec">
          <div className="att-sec-img">
            <div className="card">
              <span className="card-span">
                <img
                  class="card-img-top"
                  src={require("../../img/at01.jpeg")}
                  height="380"
                  alt="Card image cap"
                />
              </span>
              <div className="product-detail">
                <blockquote>
                  <h5 className="card-title heading text-center">
                    Marina Mall
                  </h5>
                  <p>
                    in Dubai is a renowned waterfront shopping destination,
                    offering a wide array of luxury boutiques, international
                    brands, and exquisite dining options.With its stunning
                    location in Dubai Marina, the mall provides a captivating
                    retail experience for shoppers seeking a blend of upscale
                    shopping, entertainment, and scenic views.
                  </p>
                </blockquote>
              </div>
            </div>
            <div>
              <div className="card ">
                <img
                  className="card-img-top"
                  src={require("../../img/at02.jpg")}
                  height="380"
                  alt="Card image cap"
                />
                <div className="product-detail">
                  <blockquote>
                    <h5 className="card-title heading text-center">
                      Aquaventures
                    </h5>
                    <p>
                      in Dubai is a thrilling water park that combines
                      excitement and relaxation.With its exhilarating rides,
                      iconic water slides, and a captivating aquarium, it offers
                      an unforgettable experience.From winding water coasters to
                      serene private beaches, Aquaventure is a must - visit
                      destination for water enthusiasts seeking ultimate fun in
                      Dubai.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
            <div>
              <div className="card">
                <img
                  className="card-img-top"
                  src={require("../../img/at03.jpg")}
                  height="380"
                  alt="Card image cap"
                />
                <div className="product-detail">
                  <blockquote>
                    <h5 className="card-title heading text-center">
                      Ski Dubai
                    </h5>
                    <p>
                      is an extraordinary winter wonderland located in the heart
                      of Dubai.It offers a unique skiing and snowboarding
                      experience within a snowy indoor slope.With diverse
                      activities like snow tubing and penguin encounters, it 's
                      a perfect escape to enjoy the thrill of winter sports in
                      the middle of the desert.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
            <div>
              <div className="card">
                <img
                  className="card-img-top"
                  src={require("../../img/at05.jpg")}
                  height="380"
                  alt="Card image cap"
                />
                <div className="product-detail">
                  <blockquote>
                    <h5 className="card-title heading text-center">
                      Skydive Dubai
                    </h5>
                    <p>
                      is the ultimate destination for adrenaline junkies seeking
                      an unparalleled skydiving experience.With breathtaking
                      views of Dubai 's iconic landmarks, thrill-seekers can
                      take a leap from the skies and freefall over the stunning
                      Palm Jumeirah.It 's a once-in-a-lifetime adventure that
                      offers the ultimate rush for daredevils and adventure
                      enthusiasts.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
            <div>
              <div className="card ">
                <img
                  className="card-img-top"
                  src={require("../../img/at06.jpg")}
                  height="380"
                  alt="Card image cap"
                />
                <div className="product-detail">
                  <blockquote>
                    <h5 className="card-title heading text-center">
                      Burj Khalifa
                    </h5>
                    <p>
                      The "At the Top" experience in Dubai takes you to new
                      heights of wonder.Ascend to the observation deck on the
                      iconic Burj Khalifa, the world 's tallest building, and be
                      mesmerized by breathtaking views of the city 's skyline.
                      Immerse yourself in the grandeur and beauty of Dubai from
                      this unparalleled vantage point.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
            <div>
              <div className="card">
                <img
                  className="card-img-top"
                  src={require("../../img/autom.jpg")}
                  height="380"
                  alt="Card image cap"
                />
                <div className="product-detail">
                  <blockquote>
                    <h5 className="card-title heading text-center">
                      Autodrome
                    </h5>
                    <p>
                      Dubai Autodrome is a mecca for motorsports enthusiasts,
                      offering heart - pounding racing experiences.With a world
                      - class circuit and karting track, adrenaline junkies can
                      unleash their speed and skill.From thrilling driving
                      experiences to professional racing events, Dubai Autodrome
                      is the ultimate destination for petrolheads seeking high -
                      octane excitement.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Attraction;
