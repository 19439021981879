import React from "react";
import "./footer.css";
import FTimage from "../../img/footer.jpg";
import { Link } from "react-router-dom";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="footer-container">
      <div
        className="foot-top-container"
        style={{
          maxWidth: "1200px",
        }}
      >
        <div className="foot-top">
          <div>
            <p> Phone Number </p> <h5> +971 58 525 7821</h5>
          </div>
          <div>
            <p> Email </p> <h5> info @staycia.com </h5>
          </div>
          <div>
            <p> WhatsApp </p> <h5> +971 56 374 5683 </h5>
          </div>
        </div>
      </div>
      <div className="footer-bt-container">
        <div
          style={{
            backgroundImage: `url(${FTimage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className="footer-buttom"
        >
          <div className="main-container">
            <div
              className="foot-sec"
              style={{
                maxWidth: "1200px",
              }}
            >
              <div className="foot-sec-in">
                <div className="foot-sec-in-head">
                  <div className="foot-sec-des">
                    <Link class="navbar-brand" to="/">
                      <img
                        onClick={scrollToTop}
                        src={require("../../img/logo.png")}
                        alt="Staycia_img"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="foot-sec-in">
                <div className="foot-sec-in-head">
                  <h6> HELP </h6>
                  <Link
                    className="nav-link"
                    onClick={scrollToTop}
                    to="./service"
                  >
                    Services
                  </Link>
                  <Link className="nav-link" onClick={scrollToTop} to="./why">
                    Landlord
                  </Link>
                  <Link className="nav-link" onClick={scrollToTop} to="./why">
                    Guests
                  </Link>
                  <Link className="nav-link" onClick={scrollToTop} to="./about">
                    About
                  </Link>
                  <Link
                    className="nav-link"
                    onClick={scrollToTop}
                    to="./contact"
                  >
                    Contact
                  </Link>
                </div>
              </div>
              <div className="foot-sec-in">
                <div className="foot-sec-in-head">
                  <h6> Follow us </h6>
                  <Link
                    target="blank"
                    className="nav-link"
                    to="https://www.linkedin.com/company/staycia/"
                  >
                    <img src={require("../../img/in.png")} alt="Staycia_img" />
                  </Link>
                  <Link
                    target="blank"
                    className="nav-link"
                    to="https://m.facebook.com/stayciaofficial"
                  >
                    <img
                      src={require("../../img/facebook.png")}
                      alt="Staycia_img"
                    />
                  </Link>
                  <Link
                    target="blank"
                    class="nav-link"
                    to="https://twitter.com/stayciaofficial"
                  >
                    <img
                      src={require("../../img/twitter.png")}
                      alt="Staycia_img"
                    />
                  </Link>
                  <Link
                    target="blank"
                    class="nav-link"
                    to="https://instagram.com/stayciaofficial?igshid=Y2IzZGU1MTFhOQ=="
                  >
                    <img
                      src={require("../../img/intagram.png")}
                      alt="Staycia_img"
                    />
                  </Link>
                  <Link
                    target="blank"
                    class="nav-link"
                    to="https://www.tiktok.com/@stayciaofficial?_t=8d9XrPAA2jZ&_r=1"
                  >
                    <img
                      src={require("../../img/tiktok.png")}
                      alt="Staycia_img"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="copy-right">
            <hr />
            <span
              style={{
                fontSize: "10px",
              }}
            >
              Copyright© 2023 STAYCIA UAE
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
