import React from "react";
import { Link } from "react-router-dom";
import "./stayupdate.css";

const Stayupdate = () => {
  return (
    <div className="main-container">
      <div
        className="stay-up-con"
        style={{
          maxWidth: "1200px",
        }}
      >
        <h2> STAY UPDATED </h2> <h3> Enter your email address </h3>
        <p> You will be able to receive notifications on recent offers. </p>
        <div className="reg-Form-con">
          <form className="reg-Form-stay">
            <div className="stay-up-form">
              <label className="form-label"> </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Email"
                defaultValue=""
              />
            </div>
            <button className="primary-btn"> Send us </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Stayupdate;
