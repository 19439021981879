import React from "react";
import "./gallery.scss";
import SlideShow from "../slideshow/SlideShow";
import useFetch from "../../hooks/useFetch";
import { useParams } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Gallery = ({ isOpen, closeGallery }) => {

  const options = {
    loop: true,
    responsiveClass: true,
    center: true,
    items: 1,
    margin: 0,
    autoplay: true,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  const id = useParams().id;
  const { data, loading, error } = useFetch(`/properties/${id}?populate=*`);
  return isOpen ? (
    <div className="gallery">
      <div className="gallery-content">
        <button className="close-button" onClick={closeGallery}>
          Close
        </button>
        <div className="gallary_wrap">
          <div className="slideShow ">
            <OwlCarousel className="owl-theme" {...options}>
              <div class="item">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img?.data?.attributes?.url
                  }
                  alt=""
                />
              </div>
              <div class="item">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img2?.data?.attributes?.url
                  }
                  alt=""
                />
              </div>
              <div class="item">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img3?.data?.attributes?.url
                  }
                  alt=""
                />
              </div>
              <div class="item">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img4?.data?.attributes?.url
                  }
                  alt=""
                />
              </div>
              <div class="item">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img5?.data?.attributes?.url
                  }
                  alt=""
                />
              </div>
              <div class="item">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img6?.data?.attributes?.url
                  }
                  alt=""
                />
              </div>
              <div class="item">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img7?.data?.attributes?.url
                  }
                  alt=""
                />
              </div>
              <div class="item">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img8?.data?.attributes?.url
                  }
                  alt=""
                />
              </div>
              <div class="item">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img9?.data?.attributes?.url
                  }
                  alt=""
                />
              </div>
              <div class="item">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img10?.data?.attributes?.url
                  }
                  alt=""
                />
              </div>
              <div class="item">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img11?.data?.attributes?.url
                  }
                  alt=""
                />
              </div>
              <div class="item">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img12?.data?.attributes?.url
                  }
                  alt=""
                />
              </div>
              <div class="item">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img13?.data?.attributes?.url
                  }
                  alt=""
                />
              </div>
              <div class="item">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img14?.data?.attributes?.url
                  }
                  alt=""
                />
              </div>
              <div class="item">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img15?.data?.attributes?.url
                  }
                  alt=""
                />
              </div>
              <div class="item">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img16?.data?.attributes?.url
                  }
                  alt=""
                />
              </div>
              <div class="item">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img17?.data?.attributes?.url
                  }
                  alt=""
                />
              </div>
              <div class="item">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img18?.data?.attributes?.url
                  }
                  alt=""
                />
              </div>
              <div class="item">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img19?.data?.attributes?.url
                  }
                  alt=""
                />
              </div>
              <div class="item">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img20?.data?.attributes?.url
                  }
                  alt=""
                />
              </div>
              <div class="item">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img21?.data?.attributes?.url
                  }
                  alt=""
                />
              </div>
              <div class="item">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img22?.data?.attributes?.url
                  }
                  alt=""
                />
              </div>
              <div class="item">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img23?.data?.attributes?.url
                  }
                  alt=""
                />
              </div>
              <div class="item">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img24?.data?.attributes?.url
                  }
                  alt=""
                />
              </div>
              <div class="item">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img25?.data?.attributes?.url
                  }
                  alt=""
                />
              </div>
              <div class="item">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img26?.data?.attributes?.url
                  }
                  alt=""
                />
              </div>
              <div class="item">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img27?.data?.attributes?.url
                  }
                  alt=""
                />
              </div>
              <div class="item">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img28?.data?.attributes?.url
                  }
                  alt=""
                />
              </div>
              <div class="item">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img29?.data?.attributes?.url
                  }
                  alt=""
                />
              </div>
              <div class="item">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img30?.data?.attributes?.url
                  }
                  alt=""
                />
              </div>

            </OwlCarousel>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default Gallery;
