import React from 'react'
import './modal.scss'
import useFetch from "../../hooks/useFetch";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";

const Modal = ({ isOpen, closeModal, children }) => {

       const id = useParams().id;
       const { data, loading, error } = useFetch(
         `/properties/${id}?populate=*`
       );


  return isOpen ? (
    <div className="modal">
      <div className="modal-content">
        <button className="close-button" onClick={closeModal}>
          Close
        </button>
        <div>
          <h2> About this space</h2>

          <ReactMarkdown>{data?.attributes?.list}</ReactMarkdown>
        </div>
      </div>
    </div>
  ) : null;
};

export default Modal;
