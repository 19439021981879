import React from "react";
import "./locations.css";
import Holiday from "../../img/lo-01.jpeg";
import Downtown from "../../img/lo-02.jpeg";
import pj from "../../img/lo-03.jpeg";
import golf from "../../img/lo-04.jpeg";
import HaddingBanner from "../../components/headingbanner/HaddingBanner";
import Stayupdate from "../../components/stayupdate/Stayupdate";

const Locations = () => {
  return (
    <div className="lo-container">
      <HaddingBanner hedding=" Locations" />
      <div
        className="location-data"
        style={{
          maxWidth: "1200px",
        }}
      >
        <div className="loca-head">
          <h2> Spot the best place for your STAYCATION! </h2>
        </div>
        <div className="loca-section">
          <div className="loc-card">
            <div className="loca-sec-con">
              <div
                className="loca-sec-img"
                style={{
                  backgroundImage: `url(${Holiday})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
              <div className="loc-sec-text">
                <h3> Beach Holiday </h3>
                <p>
                  Palm Jumeirah is known for glitzy hotels, posh apartment and
                  up - market global restaurants.Popular for its views of the
                  Dubai coastline and the sail - shaped Burj Al Arab hotel.beach
                  clubs with spas and infinity pools turn into boisterous
                  nightclubs with live DJs at night.
                </p>
                <div className="location-tags">
                  <span className="location-tag" title="Boat rentals">
                    Swimming
                  </span>
                  <span className="location-tag" title="Boat rentals">
                    Boat rentals
                  </span>
                  <span className="location-tag" title="Water Activities">
                    Water Activities
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="loca-sec-con">
            <div
              className="loca-sec-img"
              style={{
                backgroundImage: `url(${Downtown})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
            <div className="loc-sec-text">
              <h3> Downtown </h3>
              <p>
                The Downtown area is considered as the heart of the city and one
                of the most prestigious areas in Dubai, featuring some of the
                finest offices and residences.Downtown is well - connected by
                public transport including buses and the Metro, and is just a 15
                min drive from Dubai International Airport.
              </p>
              <div className="location-tags">
                <span className="location-tag" title="Business">
                  Business
                </span>
                <span className="location-tag" title="Meetings">
                  Meetings
                </span>
                <span className="location-tag" title="Shopping">
                  Shopping
                </span>
                <span className="location-tag" title="Restaurants">
                  Restaurants
                </span>
              </div>
            </div>
          </div>
          <div className="loca-sec-con">
            <div
              className="loca-sec-img"
              style={{
                backgroundImage: `url(${pj})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
            <div className="loc-sec-text">
              <h3> Palm Jumeirah </h3>
              <p>
                The Downtown area is considered as the heart of the city and one
                of the most prestigious areas in Dubai, featuring some of the
                finest offices and residences.Downtown is well - connected by
                public transport including buses and the Metro, and is just a 15
                min drive from Dubai International Airport.
              </p>
              <div className="location-tags">
                <span className="location-tag" title="Business">
                  Business
                </span>
                <span className="location-tag" title="Meetings">
                  Meetings
                </span>
                <span className="location-tag" title="Shopping">
                  Shopping
                </span>
                <span className="location-tag" title="Restaurants">
                  Restaurants
                </span>
              </div>
            </div>
          </div>
          <div className="loca-sec-con">
            <div
              className="loca-sec-img"
              style={{
                backgroundImage: `url(${golf})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
            <div className="loc-sec-text">
              <h3> Golf Retreat </h3>
              <p>
                Want to tee off and relax in a quiet and serene setting, there
                is no better place than DAMAC Hills, featuring a Gil Hansen
                designed 18 - hole golf course and the luxurious Trump
                International Golf Club.Adrenaline rush ? Dubai Autodrome is
                just a few minutes away!
              </p>
              <div className="location-tags">
                <span className="location-tag" title="Golf">
                  Golf
                </span>
                <span className="location-tag" title="Relaxation">
                  Relaxation
                </span>
                <span className="location-tag" title="Restaurants">
                  Restaurants
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Stayupdate />
    </div>
  );
};

export default Locations;
