import React from "react";

import "./listproperty.css";

const ListProperty = () => {
  return (
    <div className="host-container">
      <div
        className="host-con-info"
        style={{
          maxWidth: "1100px",
        }}
      >
        <h2> Join us by renting your spare home </h2>
        <div className="host-con-info-in">
          <div className="host-con-sec-icon">
            <div className="host-icon">
              <div className="host-icon-icon">
                <img
                  className="host-ico"
                  src={require("../../img/i_5.png")}
                />
              </div>
              <div className="host-icon-text">
                <span> 100 % </span>
                <h2>
                  Privacy <br /> Guaranteed
                </h2>
              </div>
            </div>
            <div className="host-icon-section-below">
              <div className="host-icon-sec-below-con">
                <h4> Know Your Benefits With Staycia </h4>
                <div className="host-icon-sec-be-con-box">
                  <div className="con-box-icon">
                    <img
                      className="host-ico"
                      src={require("../../img/i_1.png")}
                    />
                  </div>
                  <div className="con-box-text">
                    <span class="heading"> HIGH RENTAL YIELD </span>
                    <span class="description">
                      Get more rent and stable cash flow
                    </span>
                  </div>
                </div>
                <div className="host-icon-sec-be-con-box">
                  <div className="con-box-icon">
                    <img
                      className="host-ico"
                      src={require("../../img/i_2.png")}
                    />
                  </div>
                  <div className="con-box-text">
                    <span class="heading"> VERIFIED GUESTS </span>
                    <span class="description">
                      Tourists and Business Travelers are screened during the
                      booking
                    </span>
                  </div>
                </div>
                <div className="host-icon-sec-be-con-box">
                  <div className="con-box-icon">
                    <img
                      className="host-ico"
                      src={require("../../img/i_2.png")}
                    />
                  </div>
                  <div className="con-box-text">
                    <span class="heading"> WORRY - FREE MAINTENANCE </span>
                    <span class="description">
                      We handle all the maintenance so that your property
                      retains value
                    </span>
                  </div>
                </div>
                <div className="host-icon-sec-be-con-box">
                  <div className="con-box-icon">
                    <img
                      className="host-ico"
                      src={require("../../img/i_3.png")}
                    />
                  </div>
                  <div className="con-box-text">
                    <span class="heading"> FLEXIBLE LEASE </span>
                    <span class="description">
                      You can get back your property on shorter notice
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="host-con-sec-form">
            <form className="reg-Form">
              <h4> Personal details </h4>
              <div className="form-grid">
                <div className="">
                  <label className="form-label "> </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    defaultValue=""
                  />
                </div>
                <div className="">
                  <label className="form-label "> </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    defaultValue=""
                  />
                </div>
                <div className="">
                  <label className="form-label "> </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Email"
                    defaultValue=""
                  />
                </div>
                <div>
                  <label className="form-label "> </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Phone Number"
                    defaultValue=""
                  />
                </div>
              </div>
              <h4> Property details </h4>
              <div className=" pro-input">
                <label className="form-label "> </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Property Name"
                  defaultValue=""
                />
              </div>
              <div className="form-grid">
                <div className="">
                  <label className="form-label "> </label>
                  <select id="property" className="form-control">
                    <option defaultValue="Villa"> Villa </option>
                    <option defaultValue="Flat"> Flat </option>
                  </select>
                </div>
                <div className="">
                  <label className="form-label "> </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Square feet"
                    defaultValue=""
                  />
                </div>
                <div className="">
                  <label className="form-label "> </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="1"
                    defaultValue=""
                  />
                </div>
                <div>
                  <label className="form-label "> </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="1"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="">
                <textarea
                  placeholder="Address"
                  className="form-control-text"
                  name="message"
                ></textarea>
              </div>
              <button className="primary-btn"> Submit for review </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListProperty;
