import React from "react";

import "./searchresults.css";
import HotelSearch from "../../components/search/HotelSearch.js";

const SearchResults = () => {
  return (
    <div className="search-container">
      <div
        className="total-property"
        style={{
          maxWidth: "1200px",
        }}
      >
        <div className="total-pro-sec">
          <span> Showing 40 properties </span>
        </div>
        <div> </div>
      </div>
      <div
        className="search-main-con"
        style={{
          maxWidth: "1200px",
        }}
      >
        <div className="search-con"> </div>
        <div className="search-results">
          <HotelSearch />
          <HotelSearch />
          <HotelSearch />
          <HotelSearch />
          <HotelSearch />
          <HotelSearch />
        </div>
      </div>
    </div>
  );
};

export default SearchResults;
